<template lang="">
  <div>
    <div class="titles content">
      <p>YOFace 智能人像创作平台</p>
      <p class="p2">视频人脸融合服务的智能人像创作平台</p>
      <div class="img"><img src="../../assets/images/rqy.jpg"  alt=""></div>
    </div>
    <div class="box">
      <div class="content">
        <div class="title"><img src="../../assets/images/nrzzt1.png" alt=""></div>
        <div class="central">
          <div class="text">YOFAce 是一款换脸娱乐工具，可实现一键换脸，整体耗时极大缩短最大程度保留用户人脸特征让用户有极强的参与感。主要功能为视频人脸融合：输入一张静态人脸照片与一段带人脸的素材视频，输出一段融合后的视频。 </div>
          <div class="img"><img src="../../assets/images/nrzz1.png" style="width:100%; margin-right: -14px;" alt=""></div>
        </div>
      </div>
      <div class="content">
        <div class="title"><img src="../../assets/images/nrzzt2.png" alt=""></div>
        <div class="title_true">短视频快速制作</div>
        <div class="central">
          <img src="../../assets/images/nrzz2.png" style="width:100%;" alt="">
        </div>
        <div class="title_true">线上活动营销</div>
        <div class="central">
          <img src="../../assets/images/nrzz3.png" style="width:100%;" alt="">
        </div>
      </div>
      <div class="content">
         <div class="title"><img src="../../assets/images/nrzzt3.png" alt=""></div>
         <div class="central">
          <img src="../../assets/images/nrzz5.png" style="width:100%;" alt="">
        </div>
      </div>
      <div class="CoreAdvantages content">
        <div class="title_true">核心优势</div>
        <div class="c_box">
          <div class="block"><span>人脸融合</span></div>
          <div class="block"><span>人像驱动</span></div>
          <div class="block"><span>风格迁移</span></div>
          <div class="block"><span>性格变换</span></div>
        </div>
      </div>
    </div >

  </div>
</template>
<script>
export default {
}
</script>
<style scoped lang="scss">
.titles {
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  p {
    text-align: left;
    font-size: 45px;
    font-weight: 600;
    color: #333333;
    line-height: 83px;
    cursor: pointer;
  }
  .p2 {
    font-size: 42px;
  }
  img {
    margin-top: 60px;
    width: 100%;
    height: 400px;
  }
}
.box {
  margin: 40px auto 60px auto;
  max-width: 1200px;
  .content {
    margin-top: 40px;
    width: 100%;
    background-color: #fff;
    .title {
      display: flex;
    }
    .title_true {
      margin: 60px 0 40px 0;
      text-align: center;
      font-size: 45px;
      font-weight: 600;
      color: #333333;
      line-height: 63px;
      cursor: pointer;
    }
    .c_box {
      width: 100%;
      height: 350px;
      display: flex;
      justify-content: space-around;
      margin-left: -10px;
      .block {
        background-color: rgb(17, 111, 199);
        width: 18%;
        height: 100%;
        span {
          line-height: 350px;
          text-align: center;
          font-size: 30px;
          color: #FFFFFF;
          cursor: pointer;
        }
      }
    }
    .central {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      .text {
        width: 45%;
        box-sizing: border-box;
        margin-top: 45px;
        text-align: left;
        font-size: 20px;
        color: #333333;
        line-height: 40px;
        padding-right: 80px;
        padding-left: 20px;
      }
      .img{
        flex:1;
        width: 100%;
      }
    }

  }
}
</style>
